import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { fetchInAppTarget, fetchInAppTargets } from "@/api/inapps/target";
import { InAppTarget } from "@/api/inapps/typedefs/inApp";
import TargetCard from "@/components/shared/target/card/TargetCard.vue";
import { ProductLine } from "@/lib/productLine";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppTarget',
  props: {
    productLine: {},
    modelValue: {},
    isDisabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TargetCard, {
    "model-value": props.modelValue,
    "product-line": _ctx.productLine,
    hint: "Target for your in app",
    label: "Target",
    readonly: _ctx.isDisabled,
    "is-detailed-ui": "",
    "fetch-target": _unref(fetchInAppTarget),
    "fetch-targets": _unref(fetchInAppTargets),
    "target-type": "inapps",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emits('update:modelValue', $event)))
  }, null, 8, ["model-value", "product-line", "readonly", "fetch-target", "fetch-targets"]))
}
}

})